@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap');

p, a, h1, h2, h3, h4, h5, li, button{
  font-family: 'Geist Variable' !important;
} 

html{
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  table-layout: auto;
  width: 100%;
  display: block;
  overflow-x: auto; /* Umožní vodorovné posouvání tabulky */
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f9a8d4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f35caf;
}

td, th {
  word-wrap: break-word; /* Rozbíjí dlouhé řetězce na více řádků */
  white-space: normal; /* Umožňuje zalamování řádků */
  padding: 8px;
}

#main-first{
  height: calc(100vh - 64px);
}

#to-col p{
  max-width: 500px !important;
}

@media (max-width: 983px) {

  #main-first{
    align-items: start !important;
    height: auto !important;
  }
}

@media (max-width: 733px) {

  #to-col{
    flex-direction: column !important;
    gap: 10px !important;
    align-items: center !important;
    max-width: 300px !important;
    padding: 0 !important;
  }

  #profile-pic{
    margin-top: 3rem;
  }

  #coll-w{
    width: auto !important;
    max-width: 300px !important;
    margin-top: 10px !important;
  }

  #to-col p{
    max-width: 250px !important;
    text-align: center !important;
  }

  #to-col p:first-child{
    margin-top: 1rem;
  }

  #to-col p:last-child{
    margin-bottom: 1rem;
  }
}


@media (max-width: 400px) {
  td, th {
    font-size: 12px; /* Zmenšení velikosti písma na menších obrazovkách */
  }
}